import React from "react"
import PropTypes from "prop-types"

import Icon from "./icon"
import Inline from "./inline"
import Text from "./text"
import Column from "./column"
import Columns from "./columns"
import Poem from "./poem"
import Stack from "./stack"

const SyllablesEvaluation = ({ children, size, answer, solution }) => {
  const isValid = () => {
    if (typeof answer === "undefined" || answer === null) {
      return false
    }

    return solution === answer
  }
  return (
    <Columns alignY="center" collapse={[true, false]}>
      <Column>
        <Poem size={size}>{children}</Poem>
      </Column>
      <Column width="auto">
        <Columns>
          <Column width="120px">
            <Stack space="1" alignX={["left", "right"]}>
              <Inline alignY="center" space={1}>
                <Text sans bold>
                  {answer || "0"}
                </Text>
                <Icon
                  size={4}
                  icon={isValid() ? "done" : "close"}
                  color={isValid() ? "positive" : "negative"}
                />
              </Inline>
              <Text sans size={0} color="whisper">
                Deine Antwort
              </Text>
            </Stack>
          </Column>
          <Column width="80px">
            <Stack space="1" alignX={["left", "right"]}>
              <Text sans bold>
                {solution}
              </Text>
              <Text sans size={0} color="whisper">
                Lösung
              </Text>
            </Stack>
          </Column>
        </Columns>
      </Column>
    </Columns>
  )
}

SyllablesEvaluation.propTypes = {
  children: PropTypes.node,
  /** size of the poem */
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
  /** used for validating the question */
  name: PropTypes.string,
}

SyllablesEvaluation.defaultProps = {
  size: [2, 2, 3, 4],
}

export default SyllablesEvaluation
